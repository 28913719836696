@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,600;1,600;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,700;1,700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  overflow: auto;
}

.app {
  display: flex;
  position: relative;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*.cell-color.red {
    background: red;
}

.cell-color.green {
    background-color: green;
}

.cell-color.yellow {
    background-color: yellow;
}*/